@use '@angular/material' as mat;
@include mat.core();

@import 'thrive-palette';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.m2-define-palette($thrive-blue, 500);
$candy-app-accent:  mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme:  mat.m2-define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
html {
  @include mat.all-component-themes($candy-app-theme);
}
