/* You can add global styles to this file, and also import other style files */
@import "./app/themes/thrive-theme.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: #737373;
}

// Remove padding from the authentication dialog
// This needs to be here, as it is styling an entry component
//https://material.angular.io/guide/customizing-component-styles#styling-overlay-components
.app-no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}
h1 {
  font-size: 21px;
}
a {
  color: #03a9f4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// Prevents Edge specific icon to show/hide password
// https://developer.mozilla.org/en-US/docs/Web/CSS/Microsoft_Extensions
input::-ms-reveal {
  display: none;
}
